<template>
    <form class="">
        <div class="vx-row">
            <div class="vx-col mb-5 w-full">
                <vs-input type="text" v-validate="{ required: true}" v-model="data.title"
                id="inputTitle" label="Title" name="title" class="w-full" :class="{ 'is-invalid': errors.has('title') }"
                placeholder="Enter title" />
                <span class="text-danger small"  v-show="errors.has('title')">{{ errors.first('title') }}</span>
            </div>
            <div class="vx-col mb-5 w-full">
                <vs-input type="text" v-validate="{ required: true }" v-model="data.value"
                id="inputValue" label="Value" name="value" class="w-full" :class="{ 'is-invalid': errors.has('value') }"
                placeholder="Enter url or color codes" />
                <span class="text-danger small"  v-show="errors.has('value')">{{ errors.first('value') }}</span>
            </div>
            <div class="vx-col w-full">
                <div class="d-flex align-items-center justify-content-end">
                    <vs-button class="mr-2" color="primary" 
                    @click.prevent="editMode? update() : create()"
                     size="small">{{editMode? 'Update':'Submit'}}</vs-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>

export default {
    props:{
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            data: {
                title: "",
                value: ""
            },
        }
    },
    methods:{
        create() {
            this.$validator.validateAll().then(result => {
                if (!result){ this.alertError("Form is not valid"); return;}
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$vs.loading({color: "#444", type: "sound"})
                this.$http.post(`/colors/create`, formData)
                .then((response) => {
                    this.$vs.loading.close()
                    if(response.data.success){
                        this.$emit("addItem", response.data.data)
                        this.resetForm();
                    }
                })
            })
        },
        update(){
            this.$validator.validateAll().then(result => {
                if (!result){ this.alertError("Form is not valid"); return;}
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$vs.loading({color: "#444", type: "sound"})
                this.$http.post(`/colors/${this.editItem.id}/update`,formData)
                    .then((response) => {
                        this.$vs.loading.close()
                        if(response.data.success){
                            this.$emit("updateItem", response.data.data)
                            this.$emit("closeMe")
                        }
                    })
            
            })
        },
        resetForm(){
            this.data = { title: "",  value: "" }
            this.$validator.reset()
        }
    },
    mounted(){
        if(this.editMode){
            this.data = this.$options.filters
                        .patchUpdatables(this.data, this.editItem)
        }
    }
}
</script>